
import { defineComponent } from "vue";
import TTextInput from "@/tailwind/components/TTextInput.vue";
import TPrimaryButton from "@/tailwind/components/TPrimaryButton.vue";
import Http from "@/services/Http";
import Cookies from "js-cookie";
import TAlert from "@/tailwind/components/TAlert.vue";

export default defineComponent({
  components: { TAlert, TPrimaryButton, TTextInput },
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      error: "",
      loadingState: false,
    };
  },
  methods: {
    async login() {
      this.loadingState = true;
      await Http.getInstance()
        .post("auth/login", this.form)
        .then((res) => {
          Cookies.set("domnest-access-token", res.data.data.accessToken);
          setTimeout(() => {
            this.$router.push({ name: "RealizationIndex" });
          });
        })
        .catch((err) => {
          this.error = err.response.data.messages[0].content;
          this.loadingState = false;
        });
    },
  },
});
