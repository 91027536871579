
import { defineComponent } from "vue";
import TTableColumn from "@/tailwind/components/TTableColumn.vue";

export default defineComponent({
  components: { TTableColumn },
  props: {
    loadingState: {
      type: Boolean,
      default: false,
    },
    isEmpty: {
      type: Boolean,
      default: false,
    },
  },
});
