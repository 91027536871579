<template>
  <div class="sm:col-span-6">
    <label class="block text-sm font-medium text-gray-700">
      <slot name="label" />
    </label>
    <div class="mt-1 h-16 flex items-center">
      <input
        class="hidden"
        type="file"
        id="images"
        accept="image/png,image/jpeg"
        @change="change"
      />
      <label
        for="images"
        class="cursor-pointer bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
      >
        <span v-if="files.length <= 0">{{ $t("interface.select") }}</span>
        <span v-else>{{ $t("interface.change") }}</span>
      </label>
      <span
        v-for="(file, key) in files"
        :key="key"
        class="ml-4 h-16 w-16 overflow-hidden flex justify-center items-center"
      >
        <img
          class="max-w-full max-h-full rounded-md cursor-pointer"
          :src="file.preview"
          alt=""
          @click="showPreview = true"
        />
        <span
          v-if="showPreview"
          class="fixed top-0 left-0 w-screen h-screen flex justify-center items-center p-24"
          style="background-color: rgba(0, 0, 0, 0.5)"
          @click="showPreview = false"
        >
          <img
            class="max-w-full max-h-full rounded-md shadow-2xl"
            :src="file.preview"
            alt=""
          />
        </span>
      </span>
    </div>
    <p v-if="hasErrors" class="text-sm text-red-600">
      {{ errors[0] }}
    </p>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    errors: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  computed: {
    hasErrors() {
      return this.errors?.length > 0;
    },
  },
  data() {
    return {
      files: [],
      showPreview: false,
    };
  },
  methods: {
    change(event) {
      Array.from(event.target.files).forEach((file) => {
        const reader = new FileReader();
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        reader.onload = (e) => {
          that.files = [];
          that.files.push({
            preview: e.target.result,
            source: file,
          });
        };
        reader.readAsDataURL(file);
      });
    },
  },
});
</script>
