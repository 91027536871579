
import { defineComponent } from "vue";
import { ExclamationCircleIcon } from "@heroicons/vue/solid";

export default defineComponent({
  components: { ExclamationCircleIcon },
  props: {
    type: {
      type: String,
      default: "text",
    },
    modelValue: {
      type: String,
    },
    errors: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value: string) {
        this.$emit("update:modelValue", value);
      },
    },
    hasErrors() {
      return this.errors?.length > 0;
    },
    inputClass() {
      if (this.hasErrors) {
        return "border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500";
      } else {
        return "focus:ring-primary-500 focus:border-primary-500 border-gray-300";
      }
    },
  },
  emits: ["update:modelValue"],
});
