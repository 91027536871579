import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "rounded-md bg-red-50 p-4" }
const _hoisted_2 = { class: "flex" }
const _hoisted_3 = { class: "flex-shrink-0" }
const _hoisted_4 = { class: "ml-3" }
const _hoisted_5 = { class: "text-sm font-medium text-red-800" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_x_circle_icon = _resolveComponent("x-circle-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_x_circle_icon, {
          class: "h-5 w-5 text-red-400",
          "aria-hidden": "true"
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("p", _hoisted_5, [
          _renderSlot(_ctx.$slots, "default")
        ])
      ])
    ])
  ]))
}