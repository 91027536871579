import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "px-6 py-4 whitespace-nowrap text-right text-sm font-medium" }
const _hoisted_2 = { class: "px-6 py-4 whitespace-nowrap text-right text-sm font-medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("tr", null, [
    _renderSlot(_ctx.$slots, "default"),
    _createElementVNode("td", _hoisted_1, [
      _createElementVNode("a", {
        class: "text-primary-600 hover:text-primary-900 cursor-pointer",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push(_ctx.editRoute)))
      }, _toDisplayString(_ctx.$t("interface.edit")), 1)
    ]),
    _createElementVNode("td", _hoisted_2, [
      _createElementVNode("a", {
        class: "text-primary-600 hover:text-primary-900 cursor-pointer",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('delete')))
      }, _toDisplayString(_ctx.$t("interface.delete")), 1)
    ])
  ]))
}