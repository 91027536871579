import INavigationItem from "@/tailwind/interfaces/INavigationItem";
import i18n from "@/i18n";

const navigation: INavigationItem[] = [
  {
    name: i18n.global.t("menu.realizations"),
    routeName: "RealizationIndex",
    routeNames: ["RealizationIndex", "RealizationCreate", "RealizationEdit"],
  },
];

export default navigation;
