import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    type: "button",
    class: _normalizeClass(["inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none", _ctx.buttonClass])
  }, [
    (!_ctx.loadingState)
      ? _renderSlot(_ctx.$slots, "default", { key: 0 })
      : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.$t("interface.loading")), 1))
  ], 2))
}