import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sm:col-span-6" }
const _hoisted_2 = { class: "block text-sm font-medium text-gray-700" }
const _hoisted_3 = { class: "mt-1 relative" }
const _hoisted_4 = ["value"]
const _hoisted_5 = { class: "absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none" }
const _hoisted_6 = {
  key: 0,
  class: "mt-1 text-sm text-red-600"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_exclamation_circle_icon = _resolveComponent("exclamation-circle-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, [
      _renderSlot(_ctx.$slots, "label")
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("textarea", {
        class: _normalizeClass(["h-24 shadow-sm mt-1 block w-full sm:text-sm border rounded-md", _ctx.inputClass]),
        value: _ctx.modelValue,
        onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value)))
      }, null, 42, _hoisted_4),
      _createElementVNode("div", _hoisted_5, [
        (_ctx.hasErrors)
          ? (_openBlock(), _createBlock(_component_exclamation_circle_icon, {
              key: 0,
              class: "h-5 w-5 text-red-500",
              "aria-hidden": "true"
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    (_ctx.hasErrors)
      ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.errors[0]), 1))
      : _createCommentVNode("", true)
  ]))
}