
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    loadingState: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    buttonClass() {
      if (this.loadingState) {
        return ["opacity-75", "pointer-events-none"];
      }

      return [];
    },
  },
});
