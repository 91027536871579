import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import LoginIndex from "@/pages/Login/Index/Main.vue";
import RealizationIndex from "@/pages/Realization/Index/Main.vue";
import RealizationCreateOrEdit from "@/pages/Realization/CreateOrEdit/Main.vue";
import i18n from "@/i18n";
import Cookies from "js-cookie";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: { name: "RealizationIndex" },
  },
  {
    path: "/login",
    name: "LoginIndex",
    component: LoginIndex,
    meta: {
      guest: true,
    },
  },
  {
    path: "/realizations",
    name: "RealizationIndex",
    component: RealizationIndex,
    meta: {
      title: i18n.global.t("menu.realizations"),
    },
  },
  {
    path: "/realization",
    name: "RealizationCreate",
    component: RealizationCreateOrEdit,
    meta: {
      title: i18n.global.t("realizations.new"),
    },
  },
  {
    path: "/realization/:id",
    name: "RealizationEdit",
    component: RealizationCreateOrEdit,
    meta: {
      title: i18n.global.t("realizations.edit"),
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const cookie = Cookies.get("domnest-access-token");
  const guest = to.meta.guest ?? false;

  if (cookie && guest) {
    next({ name: "RealizationIndex" });
  }

  if (!cookie && !guest) {
    next({ name: "LoginIndex" });
  }

  next();
});

export default router;
