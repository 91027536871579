
import { defineComponent } from "vue";
import TTable from "@/tailwind/components/TTable.vue";
import TTableColumn from "@/tailwind/components/TTableColumn.vue";
import TTableRow from "@/tailwind/components/TTableRow.vue";
import TTableItem from "@/tailwind/components/TTableItem.vue";
import Http from "@/services/Http";
import TPrimaryButton from "@/tailwind/components/TPrimaryButton.vue";

export interface IRealization {
  id: number;
  title: string;
  power: number;
  number_of_modules: number;
  localization: string;
  description: string;
  photo: string;
}

declare interface IData {
  items: IRealization[];
  loadingState: boolean;
}

export default defineComponent({
  components: { TPrimaryButton, TTableItem, TTableRow, TTableColumn, TTable },
  data(): IData {
    return {
      items: [],
      loadingState: false,
    };
  },
  methods: {
    handleDelete(id: number) {
      this.loadingState = true;
      Http.getInstance()
        .post("realization/delete/" + id)
        .finally(() => {
          this.fetchData();
        });
    },
    fetchData() {
      this.loadingState = true;
      Http.getInstance()
        .get("realizations")
        .then((res) => {
          this.items = res.data.data;
        })
        .finally(() => {
          this.loadingState = false;
        });
    },
  },
  mounted() {
    this.fetchData();
  },
});
