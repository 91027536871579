import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = { class: "-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8" }
const _hoisted_3 = { class: "py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8" }
const _hoisted_4 = { class: "shadow overflow-hidden border-b border-gray-200 sm:rounded-lg" }
const _hoisted_5 = { class: "min-w-full divide-y divide-gray-200" }
const _hoisted_6 = { class: "bg-gray-50" }
const _hoisted_7 = { class: "bg-white divide-y divide-gray-200" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = {
  colspan: "999",
  class: "px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center"
}
const _hoisted_10 = { key: 1 }
const _hoisted_11 = {
  colspan: "999",
  class: "px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_t_table_column = _resolveComponent("t-table-column")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("table", _hoisted_5, [
            _createElementVNode("thead", _hoisted_6, [
              _createElementVNode("tr", null, [
                _renderSlot(_ctx.$slots, "columns"),
                _createVNode(_component_t_table_column, { class: "w-24 text-right" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("interface.edit")), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_t_table_column, { class: "w-24 text-right" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("interface.delete")), 1)
                  ]),
                  _: 1
                })
              ])
            ]),
            _createElementVNode("tbody", _hoisted_7, [
              (_ctx.loadingState)
                ? (_openBlock(), _createElementBlock("tr", _hoisted_8, [
                    _createElementVNode("td", _hoisted_9, _toDisplayString(_ctx.$t("interface.loading")), 1)
                  ]))
                : (_ctx.isEmpty)
                  ? (_openBlock(), _createElementBlock("tr", _hoisted_10, [
                      _createElementVNode("td", _hoisted_11, _toDisplayString(_ctx.$t("interface.noResults")), 1)
                    ]))
                  : _renderSlot(_ctx.$slots, "rows", { key: 2 })
            ])
          ])
        ])
      ])
    ])
  ]))
}