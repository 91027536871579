
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { MenuIcon, XIcon, LogoutIcon } from "@heroicons/vue/outline";
import { defineComponent } from "vue";
import INavigationItem from "@/tailwind/interfaces/INavigationItem";

export default defineComponent({
  props: {
    navigation: {
      type: Array as () => INavigationItem[],
      required: true,
    },
  },
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    MenuIcon,
    XIcon,
    LogoutIcon,
  },
  methods: {
    isActive(item: INavigationItem): boolean {
      return item.routeNames.includes(String(this.$route.name));
    },
  },
});
