<template>
  <div style="max-width: 42rem" class="w-full">
    <t-card>
      <t-form>
        <t-text-input v-model="form.title" :errors="errors.title">
          <template v-slot:label>
            {{ $t("realizations.title") }}
          </template>
        </t-text-input>
        <t-text-input v-model="form.localization" :errors="errors.localization">
          <template v-slot:label>
            {{ $t("realizations.localization") }}
          </template>
        </t-text-input>
        <t-textarea v-model="form.description" :errors="errors.description">
          <template v-slot:label>
            {{ $t("realizations.description") }}
          </template>
        </t-textarea>
        <t-file-input ref="photo" :errors="errors.photo">
          <template v-slot:label>
            {{ $t("realizations.photo") }}
          </template>
        </t-file-input>
        <div class="sm:col-span-6 flex justify-end space-x-4">
          <t-white-button @click="$router.push({ name: 'RealizationIndex' })"
            >{{ $t("interface.cancel") }}
          </t-white-button>
          <t-primary-button :loading-state="loadingState.save" @click="save"
            >{{ $t("interface.save") }}
          </t-primary-button>
        </div>
      </t-form>
      <div
        v-if="loadingState.init"
        class="absolute top-0 left-0 w-full h-full bg-white flex justify-center items-center text-sm text-gray-500"
      >
        {{ $t("interface.loading") }}
      </div>
    </t-card>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import TCard from "@/tailwind/components/TCard.vue";
import TForm from "@/tailwind/components/TForm.vue";
import TTextInput from "@/tailwind/components/TTextInput.vue";
import TTextarea from "@/tailwind/components/TTextarea.vue";
import TPrimaryButton from "@/tailwind/components/TPrimaryButton.vue";
import TWhiteButton from "@/tailwind/components/TWhiteButton.vue";
import TFileInput from "@/tailwind/components/TFileInput.vue";
import Http from "@/services/Http";
import mineTypes from "mime-types";

export default defineComponent({
  components: {
    TFileInput,
    TTextarea,
    TTextInput,
    TForm,
    TCard,
    TPrimaryButton,
    TWhiteButton,
  },
  computed: {
    isCreatePage() {
      return ["RealizationCreate"].includes(this.$route.name);
    },
  },
  data() {
    return {
      form: {
        title: "",
        localization: "",
        description: "",
      },
      errors: {
        title: [],
        localization: [],
        description: [],
        photo: [],
      },
      loadingState: {
        init: true,
        save: false,
      },
    };
  },
  methods: {
    save() {
      this.loadingState.save = true;
      const formData = new FormData();
      formData.append("title", String(this.form.title));
      formData.append("power", String(this.form.power).replace(",", "."));
      formData.append(
        "number_of_modules",
        String(this.form.number_of_modules).replace(",", ".")
      );
      formData.append("localization", String(this.form.localization));
      formData.append("description", String(this.form.description));

      this.$refs.photo.files.forEach((file) => {
        formData.append("photo", file.source);
      });

      if (this.isCreatePage) {
        Http.getInstance()
          .post("realization/create", formData)
          .then(() => {
            this.$router.push({ name: "RealizationIndex" });
          })
          .catch((err) => {
            this.errors = err.response.data.errors;
            this.loadingState.save = false;
          });
      } else {
        Http.getInstance()
          .post("realization/update/" + this.$route.params.id, formData)
          .then(() => {
            this.$router.push({ name: "RealizationIndex" });
          })
          .catch((err) => {
            this.errors = err.response.data.errors;
            this.loadingState.save = false;
          });
      }
    },
  },
  async mounted() {
    if (!this.isCreatePage) {
      await Http.getInstance()
        .get("realization/" + this.$route.params.id)
        .then(async (res) => {
          for (const property in res.data.data) {
            this.form[property] = String(res.data.data[property]);
          }
          const response = await fetch(res.data.data.photo);
          const data = await response.blob();
          const metadata = {
            type: data.type,
          };
          const file = new File(
            [data],
            "photo." + mineTypes.extension(data.type),
            metadata
          );
          this.$refs.photo.files.push({
            preview: res.data.data.photo,
            source: file,
          });
          this.loadingState.init = false;
        });
    } else {
      this.loadingState.init = false;
    }
  },
});
</script>
