
import { defineComponent } from "vue";
import TStackedLayout from "@/tailwind/components/TStackedLayout.vue";
import navigation from "@/router/navigation";
import Cookies from "js-cookie";

export default defineComponent({
  components: { TStackedLayout },
  computed: {
    isGuestPage() {
      return this.$route.meta.guest ?? false;
    },
  },
  methods: {
    logout() {
      Cookies.remove("domnest-access-token");
      this.$router.push({ name: "LoginIndex" });
    },
  },
  setup() {
    return {
      navigation,
    };
  },
});
