import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["colspan"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("th", {
    colspan: _ctx.colspan,
    scope: "col",
    class: "px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 8, _hoisted_1))
}