import axios, { AxiosInstance } from "axios";
import Cookies from "js-cookie";

export default class Http {
  public static getInstance(): AxiosInstance {
    return axios.create({
      headers: {
        Authorization: "Bearer " + Cookies.get("domnest-access-token"),
      },
      baseURL: process.env.VUE_APP_API_URL,
    });
  }
}
