
import { defineComponent } from "vue";
import { RouteLocationRaw } from "vue-router";

export default defineComponent({
  props: {
    editRoute: {
      type: Object as () => RouteLocationRaw,
      required: true,
    },
  },
});
