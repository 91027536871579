
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    colspan: {
      type: String,
      default: "1",
    },
  },
});
