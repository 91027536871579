import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col" }
const _hoisted_2 = { class: "flex justify-end mt-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_t_table_column = _resolveComponent("t-table-column")!
  const _component_t_table_item = _resolveComponent("t-table-item")!
  const _component_t_table_row = _resolveComponent("t-table-row")!
  const _component_t_table = _resolveComponent("t-table")!
  const _component_t_primary_button = _resolveComponent("t-primary-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_t_table, {
      "is-empty": _ctx.items.length <= 0,
      "loading-state": _ctx.loadingState
    }, {
      columns: _withCtx(() => [
        _createVNode(_component_t_table_column, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("realizations.title")), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_t_table_column, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("realizations.localization")), 1)
          ]),
          _: 1
        })
      ]),
      rows: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
          return (_openBlock(), _createBlock(_component_t_table_row, {
            key: item.id,
            "edit-route": { name: 'RealizationEdit', params: { id: item.id } },
            onDelete: ($event: any) => (_ctx.handleDelete(item.id))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_t_table_item, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.title), 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_t_table_item, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.localization), 1)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1032, ["edit-route", "onDelete"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["is-empty", "loading-state"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_t_primary_button, {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ name: 'RealizationCreate' })))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("realizations.new")), 1)
        ]),
        _: 1
      })
    ])
  ]))
}