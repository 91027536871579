import { createI18n } from "vue-i18n";

const messages = {
  pl: {
    interface: {
      logout: "Wyloguj sie",
      actions: "Akcje",
      edit: "Edytuj",
      delete: "Usuń",
      uploadFile: "Prześlij plik",
      orDragAndDrop: "lub przeciągnij i upuść",
      upTo: "do",
      save: "Zapisz",
      cancel: "Anuluj",
      select: "Wybierz",
      change: "Zmień",
      noResults: "Brak wyników",
      loading: "Ładowanie...",
    },
    menu: {
      realizations: "Realizacje",
    },
    realizations: {
      new: "Nowa realizacja",
      edit: "Edytuj realizacje",
      title: "Tytuł",
      description: "Opis",
      power: "Moc",
      numberOfModules: "Liczba modułów",
      localization: "Lokalizacja",
      photo: "Zdjęcie",
    },
    login: {
      email: "E-mail",
      password: "Hałso",
      login: "Zaloguj się",
    },
  },
};

export default createI18n({
  locale: "pl",
  messages,
});
